export const skills = [
  { skill: "Javascript" },
  { skill: "Typescript" },
  { skill: "Java" },
  { skill: "NodeJs" },
  { skill: "Expressjs" },
  { skill: "Spring Boot" },
  { skill: "Reactjs" },
  { skill: "Angular" },
  { skill: "Redux" },
  { skill: "Html5" },
  { skill: "Css3" },
  { skill: "Bootstrap" },
  { skill: "Tailwindcss" },
  { skill: "Vite" },
  { skill: "Docker" },
  { skill: "Postgresql" },
  { skill: "Sequelize" },
  { skill: "Mongodb" },
  { skill: "Mongoose" },
  { skill: "SQL" },
  { skill: "Jest" },
  { skill: "Mocha" },
  { skill: "Supertest" },
  { skill: "Github" },
  { skill: "SCRUM" },
  { skill: "Linux" },
  { skill: "AWS EC2" },
  { skill: "AWS S3" },
  { skill: "AWS RDS" },
];
